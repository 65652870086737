// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  RawStringPrefix = 1,
  ByteStringPrefix = 2,
  dollarString = 52,
  opHack = 53,
  BigNumberPostfix = 3,
  reservedKeyword = 54,
  unreservedKeyword = 55,
  BuiltinName = 4,
  Bool = 5,
  Comment = 6,
  Script = 7,
  Statement = 8,
  Name = 9,
  Keyword = 11,
  Operator = 12,
  String = 13,
  StringEscape = 14,
  ByteString = 15,
  ByteEscape = 16,
  Number = 17,
  Punctuation = 18,
  Cast = 19,
  ArrayType = 20,
  TupleType = 22,
  RangeType = 25,
  QueryParameter = 29,
  QueryParameterName = 31

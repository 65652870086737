export function SortIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="-0.5 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70703 0.292969C7.31641 -0.0976562 6.68359 -0.0976562 6.29297 0.292969L3.29297 3.29297C3.06348 3.52246 2.96875 3.83594 3.00879 4.13477C3.03711 4.34424 3.13184 4.54639 3.29297 4.70703C3.46387 4.87842 3.68164 4.97461 3.90527 4.99561C4.19141 5.02246 4.4873 4.92627 4.70703 4.70703L7 2.41455L9.29297 4.70703C9.68359 5.09766 10.3164 5.09766 10.707 4.70703C11.0977 4.31689 11.0977 3.68359 10.707 3.29297L7.70703 0.292969ZM3.29297 11.707L0.292969 8.70703C-0.0976562 8.31641 -0.0976562 7.68311 0.292969 7.29297C0.683594 6.90234 1.31641 6.90234 1.70703 7.29297L4 9.58545L6.29297 7.29297C6.68359 6.90234 7.31641 6.90234 7.70703 7.29297C7.88281 7.46875 7.97949 7.69434 7.99707 7.9248C8.01855 8.20508 7.92188 8.49268 7.70703 8.70703L4.70703 11.707C4.31641 12.0977 3.68359 12.0977 3.29297 11.707Z"
      />
    </svg>
  );
}

export function SortedDescIcon() {
  return (
    <svg
      width="12"
      height="15"
      viewBox="-1 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.34989 9.05152C8.72737 8.67404 9.3394 8.67404 9.71689 9.05152C10.0944 9.42901 10.0944 10.041 9.71689 10.4185L5.6862 14.4492L5.68491 14.4505L5.6835 14.4519C5.60904 14.5264 5.52545 14.5861 5.43634 14.6312C5.41447 14.6423 5.39226 14.6525 5.36978 14.6618C5.25587 14.709 5.13097 14.735 4.99999 14.735C4.86684 14.735 4.73998 14.7081 4.62456 14.6594C4.52042 14.6156 4.4224 14.5527 4.33583 14.4707C4.32811 14.4634 4.32051 14.456 4.31304 14.4484L0.283114 10.4185C-0.0943714 10.041 -0.0943714 9.42901 0.283114 9.05152C0.660601 8.67404 1.27263 8.67404 1.65011 9.05152L4.03338 11.4348L4.03338 0.966613C4.03338 0.432768 4.46614 6.36605e-09 4.99999 0C5.53384 -6.36605e-09 5.9666 0.432768 5.9666 0.966613V11.4348L8.34989 9.05152Z"
      />
    </svg>
  );
}

export function BackArrowIcon() {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L3.41437 4.99985H13C13.5523 4.99985 14 5.44756 14 5.99985C14 6.55213 13.5523 6.99985 13 6.99985H3.41406L6.70696 10.2927C7.09748 10.6833 7.09748 11.3164 6.70696 11.707C6.31643 12.0975 5.68327 12.0975 5.29274 11.707L0.29348 6.70769L0.292742 6.70696C-0.0977821 6.31643 -0.0977821 5.68327 0.292742 5.29274L0.307638 5.27815L5.29289 0.292893Z" />
    </svg>
  );
}

export function ClearFilterIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="-3 -3 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.49378 9.74371C1.15206 10.0854 0.598016 10.0854 0.256293 9.74371C-0.0854305 9.40199 -0.085431 8.84794 0.256292 8.50622L3.76251 5L0.256293 1.49378C-0.08543 1.15206 -0.0854305 0.598016 0.256293 0.256292C0.598016 -0.0854308 1.15206 -0.0854308 1.49378 0.256292L5 3.76251L8.50622 0.256292C8.84794 -0.0854308 9.40198 -0.0854308 9.74371 0.256292C10.0854 0.598016 10.0854 1.15206 9.74371 1.49378L6.23749 5L9.74371 8.50622C10.0854 8.84794 10.0854 9.40199 9.74371 9.74371C9.40198 10.0854 8.84794 10.0854 8.50622 9.74371L5 6.23749L1.49378 9.74371Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ApplyFilterIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="-1.5 -3.5 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00001 4L5.00001 8L12 1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TopRightIcon() {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6967 9.66726L10.182 1.18198M10.182 1.18198L10.182 7.54594M10.182 1.18198L3.81802 1.18198"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DeleteIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 0C4.67157 0 4 0.671573 4 1.5V2H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H2V12.5C2 13.3284 2.67157 14 3.5 14H10.5C11.3284 14 12 13.3284 12 12.5V3H13.5C13.7761 3 14 2.77614 14 2.5C14 2.22386 13.7761 2 13.5 2H10V1.5C10 0.671573 9.32843 0 8.5 0H5.5ZM9 2V1.5C9 1.22386 8.77614 1 8.5 1H5.5C5.22386 1 5 1.22386 5 1.5V2H9ZM3 3H11V12.5C11 12.7761 10.7761 13 10.5 13H3.5C3.22386 13 3 12.7761 3 12.5V3ZM5.5 5C5.77614 5 6 5.22386 6 5.5L6 10.5C6 10.7761 5.77614 11 5.5 11C5.22386 11 5 10.7761 5 10.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM9 5.5C9 5.22386 8.77614 5 8.5 5C8.22386 5 8 5.22386 8 5.5V10.5C8 10.7761 8.22386 11 8.5 11C8.77614 11 9 10.7761 9 10.5V5.5Z"
      />
    </svg>
  );
}

export const KnownScalarTypes = [
  "std::uuid",
  "std::str",
  "std::bytes",
  "std::int16",
  "std::int32",
  "std::int64",
  "std::float32",
  "std::float64",
  "std::decimal",
  "std::bool",
  "std::datetime",
  "cal::local_datetime",
  "cal::local_date",
  "cal::local_time",
  "std::duration",
  "cal::relative_duration",
  "cal::date_duration",
  "std::json",
  "std::bigint",
  "cfg::memory",
  "ext::pgvector::vector",
] as const;

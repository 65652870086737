type IconProps = React.SVGProps<SVGSVGElement>;

export function CloseIcon(props: IconProps) {
  return (
    <svg
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.35881 13.6412C0.837222 14.1196 1.61288 14.1196 2.09129 13.6412L7 8.73248L11.9087 13.6412C12.3871 14.1196 13.1628 14.1196 13.6412 13.6412C14.1196 13.1628 14.1196 12.3871 13.6412 11.9087L8.73249 7L13.6412 2.09129C14.1196 1.61288 14.1196 0.837222 13.6412 0.35881C13.1628 -0.119603 12.3871 -0.119603 11.9087 0.35881L7 5.26751L2.09129 0.35881C1.61288 -0.119603 0.837222 -0.119603 0.35881 0.35881C-0.119603 0.837222 -0.119602 1.61288 0.35881 2.09129L5.26752 7L0.358809 11.9087C-0.119604 12.3871 -0.119603 13.1628 0.35881 13.6412Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ThemeSwitcherIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99951 1C8.99951 0.447715 9.44723 0 9.99951 0C10.5518 0 10.9995 0.447715 10.9995 1V2C10.9995 2.55228 10.5518 3 9.99951 3C9.44723 3 8.99951 2.55228 8.99951 2V1ZM9.99951 13C11.6564 13 12.9995 11.6569 12.9995 10C12.9995 8.34315 11.6564 7 9.99951 7C8.34266 7 6.99951 8.34315 6.99951 10C6.99951 11.6569 8.34266 13 9.99951 13ZM9.99951 16C13.3132 16 15.9995 13.3137 15.9995 10C15.9995 6.68629 13.3132 4 9.99951 4C6.6858 4 3.99951 6.68629 3.99951 10C3.99951 13.3137 6.6858 16 9.99951 16ZM9.99951 17C9.44723 17 8.99951 17.4477 8.99951 18V19C8.99951 19.5523 9.44723 20 9.99951 20C10.5518 20 10.9995 19.5523 10.9995 19V18C10.9995 17.4477 10.5518 17 9.99951 17ZM15.6567 2.92879C16.0472 2.53826 16.6804 2.53826 17.0709 2.92879C17.4614 3.31931 17.4614 3.95248 17.0709 4.343L16.3638 5.05011C15.9732 5.44063 15.3401 5.44063 14.9496 5.05011C14.559 4.65958 14.559 4.02642 14.9496 3.63589L15.6567 2.92879ZM5.04988 14.9498C4.65936 14.5593 4.02619 14.5593 3.63567 14.9498L2.92856 15.6569C2.53804 16.0474 2.53804 16.6806 2.92856 17.0711C3.31908 17.4616 3.95225 17.4616 4.34277 17.0711L5.04988 16.364C5.4404 15.9735 5.4404 15.3403 5.04988 14.9498ZM19 9C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11H18C17.4477 11 17 10.5523 17 10C17 9.44771 17.4477 9 18 9H19ZM3 10C3 9.44772 2.55228 9 2 9H1C0.447715 9 0 9.44771 0 10C0 10.5523 0.447715 11 1 11H2C2.55228 11 3 10.5523 3 10ZM17.0712 17.0709C16.6807 17.4614 16.0475 17.4614 15.657 17.0709L14.9499 16.3638C14.5594 15.9732 14.5594 15.3401 14.9499 14.9496C15.3404 14.559 15.9736 14.559 16.3641 14.9496L17.0712 15.6567C17.4617 16.0472 17.4617 16.6804 17.0712 17.0709ZM5.05022 5.05037C5.44074 4.65984 5.44074 4.02668 5.05022 3.63615L4.34311 2.92905C3.95259 2.53852 3.31942 2.53852 2.9289 2.92905C2.53837 3.31957 2.53837 3.95274 2.9289 4.34326L3.636 5.05037C4.02653 5.44089 4.65969 5.44089 5.05022 5.05037Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ChevronIcon(props: IconProps) {
  return (
    <svg {...props} viewBox="0 0 12 12">
      <path d="M 4 2 L 9 6 L 4 10" />
    </svg>
  );
}

export function ArrowRight(props: IconProps) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6H11M6 1L11 6L6 11"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const CrossIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.393923 13.7047C-0.131308 14.2296 -0.131308 15.0811 0.393923 15.606C0.919153 16.1312 1.77003 16.1312 2.29526 15.606L8.00057 9.90068L13.7059 15.6063C14.2311 16.1312 15.082 16.1312 15.6072 15.6063C15.8633 15.3503 15.9946 15.0168 16.0011 14.6813C16.0077 14.3287 15.8764 13.9738 15.6072 13.7047L9.90191 7.99934L15.6059 2.29534C15.8856 2.01598 16.0156 1.64405 15.9985 1.27836C15.9828 0.956658 15.8515 0.63955 15.6059 0.393677C15.0807 -0.131226 14.2298 -0.131226 13.7046 0.393677L8.00057 6.09801L2.29657 0.394005C1.77134 -0.131226 0.920467 -0.131226 0.395236 0.394005C-0.129995 0.918907 -0.129995 1.77044 0.395236 2.29534L6.09924 7.99934L0.393923 13.7047Z"
      fill="currentColor"
    />
  </svg>
);

export const RunIcon = () => (
  <svg
    width="11.43"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.55844 13.2879L10.9091 7.89815C11.6017 7.49891 11.6017 6.50081 10.9091 6.10157L1.55844 0.711803C0.865802 0.312562 9.9083e-07 0.811613 9.9083e-07 1.6101L0 12.3896C0 13.1881 0.865801 13.6872 1.55844 13.2879Z"
      fill="currentColor"
    />
  </svg>
);

export const CancelQueryIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="10" height="10" x="1" y="1" rx="1" fill="currentColor" />
  </svg>
);

export const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M1 17L4.86667 13.1333M2.77778 8.11111C2.77778 12.0385 5.96153 15.2222 9.88889 15.2222C13.8162 15.2222 17 12.0385 17 8.11111C17 4.18375 13.8162 1 9.88889 1C5.96153 1 2.77778 4.18375 2.77778 8.11111Z"
      stroke="#7C7C7C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export function DropdownIcon({className}: {className?: string}) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.29297 0.292969C3.68359 -0.0976562 4.31641 -0.0976562 4.70703 0.292969L7.70703 3.29297C7.93652 3.52246 8.03125 3.83594 7.99121 4.13477C7.96289 4.34424 7.86816 4.54639 7.70703 4.70703C7.53613 4.87842 7.31836 4.97461 7.09473 4.99561C6.80859 5.02246 6.5127 4.92627 6.29297 4.70703L4 2.41455L1.70703 4.70703C1.31641 5.09766 0.683594 5.09766 0.292969 4.70703C-0.0976562 4.31689 -0.0976562 3.68359 0.292969 3.29297L3.29297 0.292969ZM4.70703 11.707L7.70703 8.70703C8.09766 8.31641 8.09766 7.68311 7.70703 7.29297C7.31641 6.90234 6.68359 6.90234 6.29297 7.29297L4 9.58545L1.70703 7.29297C1.31641 6.90234 0.683594 6.90234 0.292969 7.29297C0.117188 7.46875 0.0205078 7.69434 0.00292969 7.9248C-0.0185547 8.20508 0.078125 8.49268 0.292969 8.70703L3.29297 11.707C3.68359 12.0977 4.31641 12.0977 4.70703 11.707Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function PlusIcon() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0C4.44772 0 4 0.447716 4 1V4H1C0.447715 4 0 4.44771 0 5C0 5.55228 0.447716 6 1 6H4V9C4 9.55229 4.44772 10 5 10C5.55229 10 6 9.55228 6 9V6H9C9.55228 6 10 5.55229 10 5C10 4.44772 9.55228 4 9 4H6V1C6 0.447715 5.55229 0 5 0Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CheckIcon({className}: {className?: string}) {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.70703 6.70703L9.70703 1.70703C10.0977 1.31641 10.0977 0.683105 9.70703 0.292969C9.31641 -0.0976562 8.68359 -0.0976562 8.29297 0.292969L4 4.58545L1.70703 2.29297C1.31641 1.90234 0.683594 1.90234 0.292969 2.29297C0.117188 2.46875 0.0205078 2.69434 0.00292969 2.9248C-0.0185547 3.20508 0.078125 3.49268 0.292969 3.70703L3.29297 6.70703C3.68359 7.09766 4.31641 7.09766 4.70703 6.70703Z"
        fill="currentColor"
      />
    </svg>
  );
}

// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
import {strPrefix, dollarStr, operatorHacks, bigNumber, specializeIdent} from "./tokens.js"
const spec_operator = {__proto__:null,"<":143}
const spec_shortName = {__proto__:null,array:146, tuple:148, range:154, multirange:154}
export const parser = LRParser.deserialize({
  version: 14,
  states: "0[OYQgOOO!mQgO'#CeOOQO'#DU'#DUO$dQ`O'#DjOOQV'#Ce'#CeOOQV'#Cg'#CgOOQV'#Ch'#ChO$oOpO'#CiO$}OpO'#CiO%]O`O'#CiOOQV'#Ci'#CiO%eO`O'#CkO%mQoO'#CmOOQO'#Cq'#CqOOQO'#Cs'#CsOOQV'#Dv'#DvOOQV'#Cx'#CxO'dQ`O'#CyO'iQgO'#DOO'pQgO'#DRO'zQgO'#DSOOQV'#Dh'#DhOOQV'#DT'#DTO(RQgO'#CdQ(]Q`OOO(bQ`O'#CrO(gQ`O'#CpO(}Q`O'#CoOOQO,59Q,59QO)XQgO,5:UOOQO-E7S-E7SOOQV,5:U,5:UOOOO'#DV'#DVO+OOpO,59TOOQV,59T,59TOOOO'#DW'#DWO+^OpO,59TO+lO!bO,59TO+zO!bO,59TO,YO#tO,59VO,kO#tO,59VOOQV,59X,59XOOQV,59e,59eOOQV,59j,59jO,|QgO,59jOOQV'#D^'#D^O-TQgO,59mOOQV,59m,59mOOQV,59n,59nO-_QgO,59nOOQV-E7R-E7ROOQV'#D`'#D`O-fQgO'#D_Q(]Q`OOO-mQ`O,59^O.OQ`O,59[O.^Q&jO'#CeOOQO'#Cv'#CvO.iQ`O'#CuO.nQ&jO,59ZO.sQ`O,59fP/UQ`O'#CfOOOO-E7T-E7TOOQV1G.o1G.oOOOO-E7U-E7UOOOO'#DX'#DXO/ZO!bO1G.oOOOO'#DY'#DYO/iO!bO1G.oOOOO'#DZ'#DZO/wO#tO1G.qOOQV1G.q1G.qOOOO'#D['#D[O0YO#tO1G.qOOQV1G/U1G/UOOQV-E7[-E7[OOQV1G/X1G/XOOQV1G/Y1G/YOOQV-E7^-E7^OOQO,59y,59yOOQO-E7]-E7]O0kQ&jO'#CeO0yQ&jO'#DyO1RQ&jO'#DyO1WQ&jO1G.xO1]Q&jO1G.vO1bQ&jO,5:UO1mQ`O,59aO1uQgO1G.uO3lQ&jO1G/QOOOO-E7V-E7VOOQV7+$Z7+$ZOOOO-E7W-E7WOOOO-E7X-E7XOOQV7+$]7+$]OOOO-E7Y-E7YO4SQ&jO,5:eO4ZQ&jO,5:eO.sQ`O,5:eOOQV7+$d7+$dOOQV7+$b7+$bO4cQ&jO1G.{OOQO7+$l7+$lO4hQ&jO'#CeOOQO,59w,59wO4vQ&jO,59wO4{Q&jO1G0POOQO-E7Z-E7ZO5SQ&jO1G0POOQO7+$g7+$gO.sQ`O1G/cP3qQ`O'#D]O5[Q&jO7+%kO5cQ&jO7+%kOOQO7+$}7+$}O5kQ&jO<<IVO5rQ`O'#Dj",
  stateData: "5}~O!ZOSUOS~OPXOQZOSSOTeObeOqbOtcO!UYO!VUO!WTO!XTO!]PO!`UO!aVO!cWO!g[O!ikO!k]O!l^O!o`O!sdO~O!_lOPXXQXXSXXTXXbXXqXXtXX!TXX!UXX!VXX!WXX!XXX!]XX!`XX!aXX!cXX!gXX!iXX!kXX!lXX!oXX!qXX!sXXpXXsXX!rXX~OSoO!XoO!]mO~O^pO!arO!bpO!cpO~O^sO!asO!bsO!crO~O!auO!cvO~O!awO!cxO~ORyOPaXQaXSaXTaXbaXqaXtaX!TaX!UaX!VaX!WaX!XaX!]aX!`aX!aaX!caX!gaX!iaX!kaX!laX!oaX!qaX!saXpaXsaX!raX~OozO~Op{O~PYOs!PO!q}O~PYO!r!QO~PYO!TWX!qWX~PYO!q!TO~O!i!WO~O!i!XO~OSSO!WTO!XTO!k]O!l^O~O!]!YO!o!ZO~P(lO!_lOP!^aQ!^aS!^aT!^ab!^aq!^at!^a!T!^a!U!^a!V!^a!W!^a!X!^a!]!^a!`!^a!a!^a!c!^a!g!^a!i!^a!k!^a!l!^a!o!^a!q!^a!s!^ap!^as!^a!r!^a~O^pO!a!aO!bpO!cpO~O^sO!asO!bsO!c!aO~O!a!aO!b!cO!c!cO!d!cO~O!a!eO!b!eO!c!aO!d!eO~O`!gO!a!iO!c!gO!e!gO!f!gO~O`!jO!a!jO!c!iO!e!jO!f!jO~Op!lO~PYOs!nO!q}O~PYO!r!oO~PYO!q!TO~PYOSSO!]!sO!k]O!l^O!o!ZO~OSSO!]!YO!l^O!o!ZO~O!_lO!hXX!pXX~O!i!yO~O!h!zO~OSSO!]!YO!k]O!l^O!o!ZO~O!_lO~O!a!}O!b!cO!c!cO!d!cO~O!a!eO!b!eO!c!}O!d!eO~O`!gO!a#QO!c!gO!e!gO!f!gO~O`!jO!a!jO!c#QO!e!jO!f!jO~O!_lO!hXX!nhX!pXX~O!p#SO!h!mX~O!n#UO~O!h#VO~O!h#WO~O!_lO!h!^a!p!^a~OSSO!]!YO~OPciQciSciTcibcioniqcitci!Tci!Uci!Vci!Wci!Xci!]ci!`ci!aci!cci!gci!ici!kci!lci!oci!qci!scipcisci!rci~O!h#YO~OSSO!]#ZO!k]O!l^O!o!ZO~O!h!ma~P3qO!p#^O!h!ma~O!h#aO~O!_lO!hXX!nkX!pXX~O!n#bO~O!h!mi~P3qO!p#dO!h!mi~O!h!mq~P3qO!p#gO!h!mq~O!h!my~P3qOSoO!XoO!]!xO~O",
  goto: "+x!nPPPPPPPP!o!u#n$X$g$gP$gP$gP$g$r%X%n&W&n&q'['i$g$g'pPPP$gPP$g$g'{([(y)P)V)])c)i)o)y*P*VPPPPPPP*^P*kPPPPPPPPPPP+SPP+uQhOR!q!UbeObcdg|!O!R!UQ!]kQ!t!WQ!w!XQ!{!^Q#X!yY#[#S#^#c#d#gQ#`#UR#f#b!PQORbcdgk|!O!R!U!W!X!^!y#S#U#^#b#c#d#g#hbeObcdg|!O!R!UR!^kceObcdg|!O!R!Uw_Obcdgk|!O!R!U!W!^#S#U#^#b#c#d#gwjObcdgk|!O!R!U!W!^#S#U#^#b#c#d#gv_Obcdgk|!O!R!U!W!^#S#U#^#b#c#d#gR!w!XyiObcdgk|!O!R!U!W!X!^#S#U#^#b#c#d#gR!u!WQ!]kQ!t!WQ!w!XQ!{!^Y#[#S#^#c#d#gQ#`#UR#f#bg![k!W!X!^#S#U#^#b#c#d#gZ#]#S#^#c#d#gcaObcdg|!O!R!USgO!UQ|bQ!RdV!Sg|!RbRObcdg|!O!R!USnR#hi#hk!W!X!^!y#S#U#^#b#c#d#gQqVR!`qQtWR!btQ!duR!|!dQ!fvR#O!fQ!hwR#P!hQ!kxR#R!kQ#T!tS#_#T#eR#e#`Q!OcR!m!OQ!VhR!r!VS!Uh!VR!p!U^fObdg|!R!UT}c!O{SObcdgk|!O!R!U!W!X!^!y#S#U#^#b#c#d#gbeObcdg|!O!R!UQ!]kQ!t!WQ!{!^Y#[#S#^#c#d#gQ#`#UR#f#bR!v!W",
  nodeNames: "⚠ RawStringPrefix ByteStringPrefix BigNumberPostfix BuiltinName Bool Comment Script Statement Name ModuleName Keyword Operator String StringEscape ByteString ByteEscape Number Punctuation Cast ArrayType BuiltinName TupleType BuiltinName TupleFieldName RangeType BuiltinName TupleFieldName BuiltinName QueryParameter Cast QueryParameterName ) ( Parens } { Braces Brackets",
  maxTerm: 81,
  nodeProps: [
    ["openedBy", 32,"(",35,"{"],
    ["closedBy", 33,")",36,"}"]
  ],
  skippedNodes: [0,6],
  repeatNodeCount: 12,
  tokenData: "4Z~R|OX#{XY$QYZ$XZ]#{]^$Q^p#{pq$bqr$krs$zst%Ptu%kuv'avw'jwx'qxy'vyz(Pz{'a{|(Y|}(f}!O(q!O!P)Q!P!Q)^!Q!R)j!R![+]![!],Z!]!^,q!^!_,z!_!`'a!`!a-W!a!b-f!b!c-|!c!}.V!}#O.}#O#P/W#P#Q2{#Q#R'a#R#S.V#S#T3U#T#o.V#o#p3w#p#q'j#q#r4Q#r#s'j#s~#{U$QO!bUg$XO!bU!Zao$bO!fW!bU!Zao$kO!eW!bU!Za_$rP!eW!bU!_!`$uP$zO!`P~%PO!a~o%YQUa!eW!bUOY%`Z~%`a%eQUaOY%`Z~%`_%rU!eW!bU!Q!R&U!R![&Z!c!}&f#R#S&f#S#T&w#T#o&fP&ZOoPP&`QoP!Q![&Z#R#S&ZP&kSoP!Q![&f!c!}&f#R#S&f#T#o&fP&zRO!b'T!c#S'T#T~'TP'WRO#S'T#S#T&U#T~'T_'jO!eW!`P!bU^'qO!eW!bU~'vO!c~_(POqP!eW!bU_(YOpP!eW!bU_(cP!eW!`P!bU{|$uo(qO!p`bP!eW!bU_(xP!eW!bU!`!a({P)QObP_)ZPbP!eW!bU!^!_({_)gP!eW!`P!bU!P!Q$u_)sR!eW!gP!bU!O!P)|!g!h*h#X#Y*hP*PQ!Q![*V#R#S*VP*[S!gP!Q![*V!g!h*h#R#S*V#X#Y*hP*kS{|*w}!O*w!Q![+Q#R#S+QP*zQ!Q![+Q#R#S+QP+VQ!gP!Q![+Q#R#S+Q_+fT!eW!gP!bU!O!P)|!Q![+u!g!h*h#R#S+u#X#Y*hP+zT!gP!O!P)|!Q![+u!g!h*h#R#S+u#X#Y*ho,fQ!n`bP!eW!bU![!],l!_!`$ua,qO!_a_,zO!qP!eW!bU_-TP!eW!`P!bU!_!`$uo-cP!h`!eW!`P!bU!_!`$u_-mR!eW!bUqr-v!_!`$u!a!b$uP-yP!_!`$u_.VObP!eW!bUo.`S!eW!]a!bU!Q![.l!c!}.l#R#S.l#T#o.la.qS!]a!Q![.l!c!}.l#R#S.l#T#o.l_/WO!sP!eW!bU^/][!dSYZ0Rrs0Rwx0R!w!x0Y#O#P0R#U#V0R#Y#Z0R#b#c0R#f#g0R#h#i0R#i#j1[#l#m2cY0YO`W^QQ0]R!Q![0f!c!i0f#T#Z0fQ0iR!Q![0r!c!i0r#T#Z0rQ0uR!Q![1O!c!i1O#T#Z1OQ1RR!Q![1[!c!i1[#T#Z1[Q1_R!Q![1h!c!i1h#T#Z1hQ1kR!Q![1t!c!i1t#T#Z1tQ1wR!Q![2Q!c!i2Q#T#Z2QQ2TR!Q![2^!c!i2^#T#Z2^Q2cO^QY2fR!Q![2o!c!i2o#T#Z2oY2rR!Q![0R!c!i0R#T#Z0R_3UO!rP!eW!bUo3]R!eW!bUO!b3f!c#S3f#T~3fa3iRO#S3f#S#T3r#T~3fa3wO!]a_4QOtP!eW!bU_4ZOsP!eW!bU",
  tokenizers: [strPrefix, dollarStr, operatorHacks, bigNumber, 0, 1, 2, 3, 4],
  topRules: {"Script":[0,7]},
  dynamicPrecedences: {"29":1},
  specialized: [{term: 59, get: (value, stack) => (specializeIdent(value, stack) << 1)},{term: 62, get: value => spec_operator[value] || -1},{term: 59, get: value => spec_shortName[value] || -1}],
  tokenPrec: 0
})

export const LogoLocal = ({className}: {className?: string}) => (
  <svg
    width="153"
    height="26"
    viewBox="0 0 153 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.5366 12.8066C74.5366 18.8795 72.1017 19.968 69.3803 19.968H63.5939V5.64512H69.3803C72.1017 5.64512 74.5366 6.73366 74.5366 12.8066ZM71.5862 12.8072C71.5862 8.56764 70.2971 8.36712 68.4352 8.36712H66.6305V17.2473H68.4352C70.2971 17.2473 71.5862 17.0468 71.5862 12.8072ZM40.9345 19.968V5.64512H50.0439V8.36647H43.971V11.2883H48.5543V13.9811H43.971V17.2467H50.0439V19.968H40.9345ZM55 26H58V0H55V26ZM80.6088 13.6097V17.2477H83.1296C84.7051 17.2477 85.1062 16.2165 85.1062 15.443C85.1062 14.8415 84.8197 13.6097 82.6713 13.6097H80.6088ZM80.6088 8.36712V11.0885H82.6713C83.8458 11.0885 84.5333 10.5728 84.5333 9.71347C84.5333 8.8541 83.8458 8.36712 82.6713 8.36712H80.6088ZM77.5732 5.64512H83.4169C86.482 5.64512 87.3987 7.79356 87.3987 9.34043C87.3987 10.7727 86.482 11.804 85.8518 12.0904C87.6851 12.9785 88.0002 14.7831 88.0002 15.6998C88.0002 16.9029 87.3987 19.968 83.4169 19.968H77.5732V5.64512ZM23.49 12.8066C23.49 18.8795 21.0551 19.968 18.3337 19.968H12.5473V5.64512H18.3337C21.0551 5.64512 23.49 6.73366 23.49 12.8066ZM32.427 17.3625C33.9739 17.3625 34.7759 16.8469 35.0624 16.5032V14.9276H32.5989V12.4641H37.4973V18.1646C37.0676 18.8235 34.7186 20.1125 32.5702 20.1125C29.0468 20.1125 26.0676 18.7375 26.0676 12.6646C26.0676 6.59171 29.0754 5.50317 31.7968 5.50317C36.065 5.50317 37.1249 7.73754 37.4686 9.7141L34.9478 10.287C34.8046 9.37035 34.0025 8.22452 32.1692 8.22452C30.3072 8.22452 29.0181 8.42504 29.0181 12.6646C29.0181 16.9042 30.3645 17.3625 32.427 17.3625ZM20.5391 12.8072C20.5391 8.56764 19.2501 8.36712 17.3881 8.36712H15.5834V17.2473H17.3881C19.2501 17.2473 20.5391 17.0468 20.5391 12.8072ZM0 19.968V5.64512H9.10938V8.36647H3.03646V11.2883H7.61979V13.9811H3.03646V17.2467H9.10938V19.968H0Z"
    />
    <g opacity="0.7">
      <path d="M97.7969 20C97.7253 20 97.6667 19.9805 97.6211 19.9414C97.582 19.8958 97.5625 19.8372 97.5625 19.7656V5.95703C97.5625 5.88542 97.582 5.83008 97.6211 5.79102C97.6667 5.74544 97.7253 5.72266 97.7969 5.72266H99.8672C99.9388 5.72266 99.9941 5.74544 100.033 5.79102C100.079 5.83008 100.102 5.88542 100.102 5.95703V17.5195H105.287C105.359 17.5195 105.414 17.5423 105.453 17.5879C105.499 17.627 105.521 17.6823 105.521 17.7539V19.7656C105.521 19.8372 105.499 19.8958 105.453 19.9414C105.414 19.9805 105.359 20 105.287 20H97.7969Z" />
      <path d="M107.582 9.57031C107.582 8.93229 107.686 8.36263 107.895 7.86133C108.109 7.36003 108.425 6.93685 108.842 6.5918C109.258 6.24674 109.77 5.98307 110.375 5.80078C110.987 5.61849 111.69 5.52734 112.484 5.52734C113.24 5.52734 113.92 5.61849 114.525 5.80078C115.131 5.97656 115.645 6.23698 116.068 6.58203C116.492 6.92708 116.817 7.35026 117.045 7.85156C117.273 8.35286 117.387 8.92578 117.387 9.57031V16.2402C117.387 16.8652 117.273 17.4251 117.045 17.9199C116.817 18.4082 116.492 18.8216 116.068 19.1602C115.645 19.4987 115.131 19.7559 114.525 19.9316C113.92 20.1074 113.24 20.1953 112.484 20.1953C111.69 20.1953 110.987 20.1074 110.375 19.9316C109.763 19.7493 109.249 19.4889 108.832 19.1504C108.422 18.8118 108.109 18.3984 107.895 17.9102C107.686 17.4219 107.582 16.8652 107.582 16.2402V9.57031ZM110.121 15.3906C110.121 15.8724 110.15 16.2663 110.209 16.5723C110.274 16.8717 110.391 17.1061 110.561 17.2754C110.736 17.4447 110.977 17.5618 111.283 17.627C111.596 17.6855 111.996 17.7148 112.484 17.7148C112.973 17.7148 113.37 17.6855 113.676 17.627C113.988 17.5618 114.229 17.4447 114.398 17.2754C114.574 17.1061 114.691 16.8717 114.75 16.5723C114.815 16.2663 114.848 15.8724 114.848 15.3906V10.4688C114.848 9.96094 114.815 9.54753 114.75 9.22852C114.685 8.90951 114.564 8.66211 114.389 8.48633C114.219 8.30404 113.982 8.18034 113.676 8.11523C113.37 8.04362 112.973 8.00781 112.484 8.00781C111.996 8.00781 111.599 8.04362 111.293 8.11523C110.987 8.18034 110.746 8.30404 110.57 8.48633C110.401 8.66211 110.284 8.90951 110.219 9.22852C110.154 9.54753 110.121 9.96094 110.121 10.4688V15.3906Z" />
      <path d="M127.23 10.9277C127.159 10.9408 127.1 10.9277 127.055 10.8887C127.016 10.8496 126.996 10.7943 126.996 10.7227V9.88281C126.996 9.53776 126.957 9.24805 126.879 9.01367C126.801 8.77279 126.674 8.57747 126.498 8.42773C126.329 8.27799 126.107 8.17057 125.834 8.10547C125.561 8.04036 125.225 8.00781 124.828 8.00781C124.333 8.00781 123.933 8.04036 123.627 8.10547C123.321 8.17057 123.08 8.29427 122.904 8.47656C122.735 8.65234 122.618 8.89974 122.553 9.21875C122.494 9.53776 122.465 9.95443 122.465 10.4688V15.3906C122.465 15.8529 122.501 16.2337 122.572 16.5332C122.644 16.8327 122.768 17.0703 122.943 17.2461C123.119 17.4219 123.36 17.5456 123.666 17.6172C123.972 17.6823 124.359 17.7148 124.828 17.7148C125.238 17.7148 125.58 17.6855 125.854 17.627C126.133 17.5684 126.358 17.474 126.527 17.3438C126.697 17.207 126.817 17.028 126.889 16.8066C126.96 16.5853 126.996 16.3086 126.996 15.9766V15.0977C126.996 15.026 127.016 14.9707 127.055 14.9316C127.1 14.8861 127.159 14.8633 127.23 14.8633H129.301C129.372 14.8633 129.428 14.8861 129.467 14.9316C129.512 14.9707 129.535 15.026 129.535 15.0977V15.752C129.535 17.2168 129.138 18.3236 128.344 19.0723C127.549 19.821 126.378 20.1953 124.828 20.1953C124.053 20.1953 123.363 20.1074 122.758 19.9316C122.152 19.7493 121.638 19.4922 121.215 19.1602C120.798 18.8216 120.479 18.4082 120.258 17.9199C120.036 17.4251 119.926 16.8652 119.926 16.2402V9.57031C119.926 8.24219 120.339 7.23633 121.166 6.55273C121.993 5.86914 123.214 5.52734 124.828 5.52734C126.378 5.52734 127.549 5.91146 128.344 6.67969C129.138 7.44141 129.535 8.56771 129.535 10.0586V10.459C129.535 10.5306 129.512 10.5892 129.467 10.6348C129.428 10.6803 129.372 10.7064 129.301 10.7129L127.23 10.9277Z" />
      <path d="M131.488 20C131.417 20 131.365 19.9805 131.332 19.9414C131.299 19.9023 131.293 19.847 131.312 19.7754L134.848 5.94727C134.893 5.79753 134.991 5.72266 135.141 5.72266H138.256C138.406 5.72266 138.503 5.79753 138.549 5.94727L142.084 19.7754C142.13 19.9251 142.071 20 141.908 20H139.838C139.688 20 139.59 19.9251 139.545 19.7754L138.695 16.4355H134.701L133.852 19.7754C133.806 19.9251 133.708 20 133.559 20H131.488ZM135.336 13.9551H138.061L136.703 8.64258L135.336 13.9551Z" />
      <path d="M144.691 20C144.62 20 144.561 19.9805 144.516 19.9414C144.477 19.8958 144.457 19.8372 144.457 19.7656V5.95703C144.457 5.88542 144.477 5.83008 144.516 5.79102C144.561 5.74544 144.62 5.72266 144.691 5.72266H146.762C146.833 5.72266 146.889 5.74544 146.928 5.79102C146.973 5.83008 146.996 5.88542 146.996 5.95703V17.5195H152.182C152.253 17.5195 152.309 17.5423 152.348 17.5879C152.393 17.627 152.416 17.6823 152.416 17.7539V19.7656C152.416 19.8372 152.393 19.8958 152.348 19.9414C152.309 19.9805 152.253 20 152.182 20H144.691Z" />
    </g>
  </svg>
);

export const LogoCloud = ({className}: {className?: string}) => (
  <svg
    width="155"
    height="26"
    viewBox="0 0 155 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.5366 12.8066C74.5366 18.8795 72.1017 19.968 69.3803 19.968H63.5939V5.64512H69.3803C72.1017 5.64512 74.5366 6.73366 74.5366 12.8066ZM71.5862 12.8072C71.5862 8.56764 70.2971 8.36712 68.4352 8.36712H66.6305V17.2473H68.4352C70.2971 17.2473 71.5862 17.0468 71.5862 12.8072ZM40.9345 19.968V5.64512H50.0439V8.36647H43.971V11.2883H48.5543V13.9811H43.971V17.2467H50.0439V19.968H40.9345ZM55 26H58V0H55V26ZM80.6088 13.6097V17.2477H83.1296C84.7051 17.2477 85.1062 16.2165 85.1062 15.443C85.1062 14.8415 84.8197 13.6097 82.6713 13.6097H80.6088ZM80.6088 8.36712V11.0885H82.6713C83.8458 11.0885 84.5333 10.5728 84.5333 9.71347C84.5333 8.8541 83.8458 8.36712 82.6713 8.36712H80.6088ZM77.5732 5.64512H83.4169C86.482 5.64512 87.3987 7.79356 87.3987 9.34043C87.3987 10.7727 86.482 11.804 85.8518 12.0904C87.6851 12.9785 88.0002 14.7831 88.0002 15.6998C88.0002 16.9029 87.3987 19.968 83.4169 19.968H77.5732V5.64512ZM23.49 12.8066C23.49 18.8795 21.0551 19.968 18.3337 19.968H12.5473V5.64512H18.3337C21.0551 5.64512 23.49 6.73366 23.49 12.8066ZM32.427 17.3625C33.9739 17.3625 34.7759 16.8469 35.0624 16.5032V14.9276H32.5989V12.4641H37.4973V18.1646C37.0676 18.8235 34.7186 20.1125 32.5702 20.1125C29.0468 20.1125 26.0676 18.7375 26.0676 12.6646C26.0676 6.59171 29.0754 5.50317 31.7968 5.50317C36.065 5.50317 37.1249 7.73754 37.4686 9.7141L34.9478 10.287C34.8046 9.37035 34.0025 8.22452 32.1692 8.22452C30.3072 8.22452 29.0181 8.42504 29.0181 12.6646C29.0181 16.9042 30.3645 17.3625 32.427 17.3625ZM20.5391 12.8072C20.5391 8.56764 19.2501 8.36712 17.3881 8.36712H15.5834V17.2473H17.3881C19.2501 17.2473 20.5391 17.0468 20.5391 12.8072ZM0 19.968V5.64512H9.10938V8.36647H3.03646V11.2883H7.61979V13.9811H3.03646V17.2467H9.10938V19.968H0Z"
    />
    <g opacity="0.7">
      <path d="M104.672 10.9277C104.6 10.9408 104.542 10.9277 104.496 10.8887C104.457 10.8496 104.438 10.7943 104.438 10.7227V9.88281C104.438 9.53776 104.398 9.24805 104.32 9.01367C104.242 8.77279 104.115 8.57747 103.939 8.42773C103.77 8.27799 103.549 8.17057 103.275 8.10547C103.002 8.04036 102.667 8.00781 102.27 8.00781C101.775 8.00781 101.374 8.04036 101.068 8.10547C100.762 8.17057 100.521 8.29427 100.346 8.47656C100.176 8.65234 100.059 8.89974 99.9941 9.21875C99.9355 9.53776 99.9062 9.95443 99.9062 10.4688V15.3906C99.9062 15.8529 99.9421 16.2337 100.014 16.5332C100.085 16.8327 100.209 17.0703 100.385 17.2461C100.561 17.4219 100.801 17.5456 101.107 17.6172C101.413 17.6823 101.801 17.7148 102.27 17.7148C102.68 17.7148 103.021 17.6855 103.295 17.627C103.575 17.5684 103.799 17.474 103.969 17.3438C104.138 17.207 104.258 17.028 104.33 16.8066C104.402 16.5853 104.438 16.3086 104.438 15.9766V15.0977C104.438 15.026 104.457 14.9707 104.496 14.9316C104.542 14.8861 104.6 14.8633 104.672 14.8633H106.742C106.814 14.8633 106.869 14.8861 106.908 14.9316C106.954 14.9707 106.977 15.026 106.977 15.0977V15.752C106.977 17.2168 106.579 18.3236 105.785 19.0723C104.991 19.821 103.819 20.1953 102.27 20.1953C101.495 20.1953 100.805 20.1074 100.199 19.9316C99.5938 19.7493 99.0794 19.4922 98.6562 19.1602C98.2396 18.8216 97.9206 18.4082 97.6992 17.9199C97.4779 17.4251 97.3672 16.8652 97.3672 16.2402V9.57031C97.3672 8.24219 97.7806 7.23633 98.6074 6.55273C99.4342 5.86914 100.655 5.52734 102.27 5.52734C103.819 5.52734 104.991 5.91146 105.785 6.67969C106.579 7.44141 106.977 8.56771 106.977 10.0586V10.459C106.977 10.5306 106.954 10.5892 106.908 10.6348C106.869 10.6803 106.814 10.7064 106.742 10.7129L104.672 10.9277Z" />
      <path d="M110.141 20C110.069 20 110.01 19.9805 109.965 19.9414C109.926 19.8958 109.906 19.8372 109.906 19.7656V5.95703C109.906 5.88542 109.926 5.83008 109.965 5.79102C110.01 5.74544 110.069 5.72266 110.141 5.72266H112.211C112.283 5.72266 112.338 5.74544 112.377 5.79102C112.423 5.83008 112.445 5.88542 112.445 5.95703V17.5195H117.631C117.702 17.5195 117.758 17.5423 117.797 17.5879C117.842 17.627 117.865 17.6823 117.865 17.7539V19.7656C117.865 19.8372 117.842 19.8958 117.797 19.9414C117.758 19.9805 117.702 20 117.631 20H110.141Z" />
      <path d="M119.926 9.57031C119.926 8.93229 120.03 8.36263 120.238 7.86133C120.453 7.36003 120.769 6.93685 121.186 6.5918C121.602 6.24674 122.113 5.98307 122.719 5.80078C123.331 5.61849 124.034 5.52734 124.828 5.52734C125.583 5.52734 126.264 5.61849 126.869 5.80078C127.475 5.97656 127.989 6.23698 128.412 6.58203C128.835 6.92708 129.161 7.35026 129.389 7.85156C129.617 8.35286 129.73 8.92578 129.73 9.57031V16.2402C129.73 16.8652 129.617 17.4251 129.389 17.9199C129.161 18.4082 128.835 18.8216 128.412 19.1602C127.989 19.4987 127.475 19.7559 126.869 19.9316C126.264 20.1074 125.583 20.1953 124.828 20.1953C124.034 20.1953 123.331 20.1074 122.719 19.9316C122.107 19.7493 121.592 19.4889 121.176 19.1504C120.766 18.8118 120.453 18.3984 120.238 17.9102C120.03 17.4219 119.926 16.8652 119.926 16.2402V9.57031ZM122.465 15.3906C122.465 15.8724 122.494 16.2663 122.553 16.5723C122.618 16.8717 122.735 17.1061 122.904 17.2754C123.08 17.4447 123.321 17.5618 123.627 17.627C123.939 17.6855 124.34 17.7148 124.828 17.7148C125.316 17.7148 125.714 17.6855 126.02 17.627C126.332 17.5618 126.573 17.4447 126.742 17.2754C126.918 17.1061 127.035 16.8717 127.094 16.5723C127.159 16.2663 127.191 15.8724 127.191 15.3906V10.4688C127.191 9.96094 127.159 9.54753 127.094 9.22852C127.029 8.90951 126.908 8.66211 126.732 8.48633C126.563 8.30404 126.326 8.18034 126.02 8.11523C125.714 8.04362 125.316 8.00781 124.828 8.00781C124.34 8.00781 123.943 8.04362 123.637 8.11523C123.331 8.18034 123.09 8.30404 122.914 8.48633C122.745 8.66211 122.628 8.90951 122.562 9.22852C122.497 9.54753 122.465 9.96094 122.465 10.4688V15.3906Z" />
      <path d="M141.898 5.72266C141.97 5.72266 142.025 5.74544 142.064 5.79102C142.11 5.83008 142.133 5.88542 142.133 5.95703V14.7949C142.133 15.752 142.022 16.5723 141.801 17.2559C141.586 17.9395 141.27 18.4993 140.854 18.9355C140.443 19.3717 139.942 19.6908 139.35 19.8926C138.757 20.0944 138.09 20.1953 137.348 20.1953H137.055C136.319 20.1953 135.655 20.0911 135.062 19.8828C134.477 19.6745 133.975 19.3522 133.559 18.916C133.142 18.4733 132.823 17.9134 132.602 17.2363C132.38 16.5527 132.27 15.7389 132.27 14.7949V5.95703C132.27 5.88542 132.289 5.83008 132.328 5.79102C132.374 5.74544 132.432 5.72266 132.504 5.72266H134.574C134.646 5.72266 134.701 5.74544 134.74 5.79102C134.786 5.83008 134.809 5.88542 134.809 5.95703V14.209C134.809 14.8014 134.838 15.319 134.896 15.7617C134.962 16.1979 135.076 16.5625 135.238 16.8555C135.408 17.1419 135.635 17.3568 135.922 17.5C136.215 17.6432 136.592 17.7148 137.055 17.7148H137.348C137.829 17.7148 138.217 17.6497 138.51 17.5195C138.803 17.3828 139.031 17.1712 139.193 16.8848C139.356 16.5983 139.464 16.2337 139.516 15.791C139.568 15.3483 139.594 14.821 139.594 14.209L139.584 5.95703C139.584 5.88542 139.604 5.83008 139.643 5.79102C139.688 5.74544 139.747 5.72266 139.818 5.72266H141.898Z" />
      <path d="M145.297 20C145.225 20 145.167 19.9805 145.121 19.9414C145.082 19.8958 145.062 19.8372 145.062 19.7656V5.95703C145.062 5.88542 145.082 5.83008 145.121 5.79102C145.167 5.74544 145.225 5.72266 145.297 5.72266H150.805C152.035 5.72266 152.963 6.04818 153.588 6.69922C154.219 7.34375 154.535 8.30078 154.535 9.57031V16.2402C154.535 17.4772 154.219 18.4147 153.588 19.0527C152.963 19.6842 152.035 20 150.805 20H145.297ZM151.996 15.5469V10.2148C151.996 9.76562 151.977 9.41081 151.938 9.15039C151.898 8.88346 151.814 8.68164 151.684 8.54492C151.553 8.40169 151.365 8.31055 151.117 8.27148C150.876 8.22591 150.551 8.20312 150.141 8.20312H147.602V17.5195H150.141C150.551 17.5195 150.88 17.5 151.127 17.4609C151.374 17.4154 151.56 17.3242 151.684 17.1875C151.814 17.0508 151.898 16.8522 151.938 16.5918C151.977 16.3314 151.996 15.9831 151.996 15.5469Z" />
    </g>
  </svg>
);
